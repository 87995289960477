import '../styles/faq.scss';

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import UpArrow from '../images/UpArrow.jpeg';

function FaqPage() {

  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick_section ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick ' + event.target.name, showResults[index] );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };

  const [ showSections, setShowSections ] = React.useState( { 1: true } );
  const [ showResults, setShowResults ] = React.useState( {} );

  return (
    <Layout header={'menu'}>
      <SEO
        title="Get answers related to Life &amp; Home Insurance Queries "
        description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
        canonicalUrl='https://www.getmybubble.io/faq'
        robots={ { 1:'index', 2: 'follow' } }
      />

      <div id="faq" className="wrapper">
        <div className="flexColumn carrierCommonPage">
          <div className="company-info-container container-fluid">
            <div className="row">
              <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                                    Frequently Asked Questions
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container faq-margin-bottom common-container-space">
            <div className="row">
              <div className="col-lg-12">
                {/*About Home Insurance*/}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faqFlex bg-white-border">
                      <div className="faqLeftContainer faq-left-container">
                        <div className="col-sm-12" onClick={( e ) => onClick_section( e, 1 )}>
                          <div className="align-faq-que position-relative">
                            <div className="faq-section-headings">
                                                                Application
                            </div>

                            <img
                              src={showSections[1] ? UpArrow : DownArrow}
                              className="faq-dd m-0" name="1" alt="Bubble Life Insurance"/></div>
                        </div>

                        <div className="col-12 both-space-padding">
                          <div className="row">
                            {showSections[1] &&
                                                                <>
                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 3 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> What if my insurance
                                                                                                application is denied? </div><img src={showResults[3] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="10" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[3] && `Finding out the reasons
                                                                                            why your application was denied is most important. You can then work
                                                                                            towards fixing those problems. If it gets too complicated or is beyond
                                                                                            your understanding, you can talk to our specialist who will help you
                                                                                            every step of the way.`}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 4 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> What should I keep in mind
                                                                                                before applying for home insurance? </div><img src={showResults[4] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="23" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[4] && `A few things to consider
                                                                                            are knowing what all you need coverage, how much coverage, choosing the
                                                                                            right policy, and deductible options.`}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 5 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> What do I need to provide
                                                                                                while applying for a life insurance policy? </div><img src={showResults[5] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="47" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[5] && `Life insurance
                                                                                            applications generally need yours and your family's medical history,
                                                                                            details of your beneficiary, history of any DUIs, information of risky
                                                                                            hobbies like sky diving, standard forms of identification, and other
                                                                                            such details.`}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 6 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> Do I need to get a medical
                                                                                                exam to get a life insurance policy? </div><img src={showResults[6] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="50" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[6] && `The younger you are and
                                                                                            the better health (no smoking, no pre-existing conditions, etc) you are
                                                                                            in, the probability of you needing a medical exam is low.`}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 7 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> What do I need to provide
                                                                                                while applying for a life insurance policy? </div><img src={showResults[7] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="52" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[7] && `Life insurance
                                                                                            applications generally need yours and your family's medical history,
                                                                                            details of your beneficiary, history of any DUIs, information of risky
                                                                                            hobbies like sky diving, standard forms of identification, and other
                                                                                            such details.`}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 8 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> What should I keep in mind
                                                                                                before applying for home insurance? </div><img src={showResults[8] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="54" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[8] && `A few things to consider
                                                                                            are knowing what all you need coverage, how much coverage, choosing the
                                                                                            right policy, and deductible options.`}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*End of Home Insurance */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FaqPage;
